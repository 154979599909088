<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                    <!-- <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link> &gt;
                    <router-link :to="{ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.$route.params.domain } }">{{ this.$route.params.domain }}</router-link> -->
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Create new website</h1>
                <!-- <p class="text-caption text-center">Websites</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Create new website</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text class="px-5">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <!-- TODO: alternative: allow user to enter the FQDN here... parse out the domain name, hostname, match against account, if it's not found show an error message and maybe these selects so user can get to what they have; also, being able to ADD new names from here (at least for domains already verified) would be convenient -->
                            <SelectDomain v-model="domain"/>

                            <v-select
                                ref="hostnameInput"
                                v-model="hostname"
                                label="Hostname"
                                :items="hostnameChoices"
                                color="purple"
                                required
                                outlined
                                hint="Leave blank or use '@' for using the primary domain only without a subdomain"
                                >
                            </v-select>

                            <v-select
                                ref="siteTypeInput"
                                v-model="newSiteType"
                                label="Site type"
                                :items="newSiteTypeChoices"
                                color="purple"
                                required
                                outlined
                                >
                            </v-select>
                            <v-text-field
                                ref="siteNameInput"
                                v-model="newSiteName"
                                label="Site name"
                                :rules="newSiteNameRules"
                                validate-on-blur
                                color="purple"
                                required
                                hint="A name for the site"
                                type="text"
                                outlined
                            >
                            </v-text-field>
                            <v-text-field
                                ref="sitePathInput"
                                v-model="newSitePath"
                                label="Path"
                                :rules="newSitePathRules"
                                validate-on-blur
                                color="purple"
                                required
                                hint="Path where the site will be deployed"
                                type="text"
                                outlined
                            >
                            </v-text-field>

                            <!-- TODO: optional, select "zone" where it can be hosted (doesn't affect who can access it), use account setting if specified, otherwise default is anywhere -->
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="create" :disabled="!isFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
import SelectDomain from '@/components/select-item/SelectDomain.vue';

export default {
    components: {
        SelectDomain,
    },
    data: () => ({
        error: null,
        account: null,
        domain: null,
        // create site
        createSiteForm: null,
        hostname: null,
        hostnameChoices: [], // will be filled in after user selects domain
        newSiteType: 'static',
        newSiteTypeChoices: [
            { value: 'static', text: 'Static site' },
            // { value: 'wordpress', text: 'WordPress site' }, // because we need to set up the php configuration block in nginx
        ],
        newSiteName: null,
        newSiteNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Site name is required',
        ],
        newSitePath: '/',
        newSitePathRules: [
            (v) => !!v || 'Path is required',
            (v) => !v || v.startsWith('/') || 'Path must start with forward slash',
        ],
        // add site
        addSiteChoices: [],
        addSiteId: null,
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        isFormComplete() {
            // TODO: check that site name is not too similar to existing site name
            return typeof this.newSiteName === 'string' && this.newSiteName.trim().length > 0;
        },
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
    },
    watch: {
        domain(newValue) {
            this.loadHostnameChoices(newValue);
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadHostnameChoices(domain) {
            try {
                this.$store.commit('loading', { loadHostnameChoices: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchDnsRecord({ domain, type: 'A' });
                console.log(`loadHostnameChoices: response ${JSON.stringify(response)}`);
                if (typeof response === 'object' && Array.isArray(response.list)) {
                    // this.dnsRecordList = response.list;
                    this.hostnameChoices = response.list.map((item) => item.key);
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadHostnameChoices: false });
            }
        },
        create() {
            if (!this.isFormComplete) {
                return;
            }
            this.createSite();
        },
        cancel() {
            // this.$emit('cancel');
            this.$router.go(-1);
        },
        async createSite() {
            try {
                this.error = false;
                this.$store.commit('loading', { createSite: true });
                console.log('createSite');
                const request = {
                    domain: this.domain,
                    hostname: this.hostname,
                    path: this.newSitePath,
                    type: this.newSiteType,
                    label: this.newSiteName,
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.createWebsite(request);
                console.log('createSite response: %o', response);
                const { isCreated, id, error } = response;
                if (isCreated) {
                    // const site = { id, ...request };
                    // this.list.push(site); // this SHOULD work (https://vuejs.org/v2/guide/list.html#Mutation-Methods) but doesn't work.
                    this.$router.replace({ name: 'account-edit-website', params: { accountId: this.$route.params.accountId, websiteId: id }, query: { domain: this.domain } });
                } else if (error) {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website' });
                    this.error = error;
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website' });
                    this.error = 'Request failed';
                }
                this.$emit('created', { id });
            } catch (err) {
                console.error('failed to create site', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create website' });
            } finally {
                this.$store.commit('loading', { createSite: false });
            }
        },
    },

    mounted() {
        this.loadAccount();
        if (this.$route.query.domain) {
            this.domain = this.$route.query.domain;
        }
    },
};
</script>
