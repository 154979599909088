<template>
    <v-select outlined v-model="domain" label="Select domain" :items="list" item-text="name" item-value="name">
            <!-- <v-btn icon color="purple" :to="{ name: 'account-create-domain', params: { accountId: this.$route.params.accountId } }">
                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
            </v-btn> -->
    </v-select>
</template>

<script>
export default {
    // TODO: prop should allow to filter domains based on status,m e.g. only show 'verified' domains
    props: {
        value: {
            type: String,
            default: null,
            required: false,
        },
    },

    data: () => ({
        // TODO: eliminate these temporary flags and usage in the template; maybe create a different component for "select a domain"
        domain: null,
        list: [],
    }),

    watch: {
        domain(newValue) {
            this.$emit('input', newValue);
        },
    },

    methods: {
        async loadDomainList() {
            try {
                this.$store.commit('loading', { loadDomainList: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.list();
                if (response?.list) {
                    this.list = response.list;
                }
            } catch (err) {
                console.error('loadDomainList failed', err);
            } finally {
                this.$store.commit('loading', { loadDomainList: false });
            }
        },
    },

    mounted() {
        this.loadDomainList();
    },
};
</script>
